<template>
  <div id="upcomingRenewals">
      <card type="primary">
          <div class="row" id="root">
              <div class="col">
                  <h4 class="card-title">Upcoming Programmes Renewals</h4>
                  <br />
                  <span>Please find a list of upcoming programmes renewals below.</span>
              </div>
          </div>

          <div class="row">
              <div class="col-4">
                  <div v-if="usingProducingOfficeDropdown()" class="form-group">
                      <label for="selProducingOffice">Class of Business</label>
                      <v-select
                              id="selClassOfBusiness"
                              aria-describedby="spnPolicyClassError"
                              :class="{ 'is-invalid': classTypeHasError }"
                              label="policy_class_type_caption"
                              :options="$store.getters.classTypes"
                              :disabled="$store.getters.classTypes.length===0"
                              :value="$store.getters.upcomingRenewals.classType"
                              :clearable="true"
                              placeholder="Select a Class of Business"
                              @input="val => changeClassOfBusiness(val)"
                      ></v-select>
                      <span id="spnProducingOfficeError" class="invalid-feedback" v-if="classTypeHasError">{{ classTypeError }}</span>
                  </div>
              </div>
          </div>

          <hr />
          <div class="mt-3">

              <div v-if="loadingProgrammes">
                  <b-spinner label="Loading..."></b-spinner>
              </div>
              <div v-else>
                  <b-row align-v="end" align-h="end">
                      <b-col lg="4" class="my-1">
                          <b-form-group>
                              <b-input-group size="sm">
                                  <b-form-input
                                      id="filter-input"
                                      v-model="filter"
                                      type="search"
                                      placeholder="Type to Search"
                                  ></b-form-input>

                                  <b-input-group-append>
                                      <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                  </b-input-group-append>
                              </b-input-group>
                          </b-form-group>
                      </b-col>
                  </b-row>

                  <b-row>
                      <b-col sm="12" class="my-1">
                          <b-table striped hover :items="programmeList" :fields="fields"
                                   :current-page="currentPage"
                                   :per-page="perPage"
                                   :filter="filter"
                                   :filter-included-fields="filterOn"
                                   :sort-by.sync="sortBy"
                                   :sort-desc.sync="sortDesc">
                          </b-table>
                      </b-col>
                  </b-row>

                  <div class="row">
                      <div class="col-12">
                          <b-pagination
                              v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              align="left"
                              size="sm"
                              class="my-0"
                          ></b-pagination>
                      </div>
                  </div>
              </div>
          </div>
      </card>
  </div>
</template>
<script>
import axios from "axios";
import Card from "../components/Cards/Card";
import LoginModal from "../components/LoginModal";
import FeaturesMixin from "@/mixins/features.mixin";
import DefaultsService from "@/services/defaults.service";

export default {
    name: "UpcomingRenewals",
    components: {
        Card,
        LoginModal
    },
    mixins: [FeaturesMixin],
    data: () => {
        return {
            message: "This is the message",
            loadingProgrammes: true,
            loadingStateId: 0,
            deletingStateId: 0,
            programmeList: [],
            selectedPolicyId: null,
            // Table settings
            fields: [
                { key: 'client.client_name', label: 'Client', sortable: true },
                { key: 'master_policy_name', label: 'Master Policy Name', sortable: true },
                { key: 'multinational_programme_number', label: 'MPID', sortable: true },
                { key: 'date_inception', label: 'Inception Date', sortable: true },
                { key: 'date_expiry', label: 'Expiry Date', sortable: true },
                { key: 'class_type.policy_class_type_caption', label: 'Policy Class', sortable: true },
                { key: 'primary_handler.full_name', label: 'Primary Handler', sortable: true },
                { key: 'secondary_handler.full_name', label: 'Secondary Handler', sortable: true },
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            sortBy: 'date_expiry',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        };
    },
    props: {
        classTypeHasError: {
            type: Boolean,
            default: false,
        },
        classTypeError: {
            type: String,
            default: ""
        },
    },
    methods: {
        async getUpcomingRenewals() {
            this.loadingProgrammes = true;

            if(this.$store.getters.upcomingRenewals.classType) {
                this.selectedPolicyId = this.$store.getters.upcomingRenewals.classType.policy_class_type_id;
            }

            try {
                let { data } = await axios.post(
                    process.env.VUE_APP_API_ENDPOINT + "/programme/upcomingRenewals",
                    {
                        email: this.$store.getters.user.email,
                        policy_class_type_id: this.selectedPolicyId
                    },
                    {
                        withCredentials: true
                    }
                );
                this.programmeList = data.programmes;
                this.totalRows = data.programmes.length;
                this.loadingProgrammes = false;
            } catch (err) {
                console.error(err);
            }
        },
        changeClassOfBusiness(policyClass) {
            this.classTypeHasError = false;
            this.classTypeError = "";

            if(policyClass) {
                this.$store.getters.upcomingRenewals.classType = policyClass;
            } else {
                this.$store.getters.upcomingRenewals.classType = null;
                this.selectedPolicyId = null;
            }

            this.getUpcomingRenewals();
        },
    },
    mounted() {
        if (this.$store.getters.isLoggedIn) {
            this.getUpcomingRenewals();
        }
    }
};
</script>